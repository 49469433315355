import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Experience from "components/experience"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "blog" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              lhp
              lhs
              intro
              bio
              bio_text
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "potrait.jpg" }) {
      childImageSharp {
        fixed(width: 250, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            templateKey
            title
            description
          }
        }
      }
    }
  }
`

const Image = data => {
  return (
    <Img
      fixed={data.placeholderImage.childImageSharp.fixed}
      alt="Nwachukwu Chibuike"
      className="bio-image"
    />
  )
}

const BlogPage = props => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  let {
    allMarkdownRemark: { edges },
  } = props.data
  edges = edges.filter(post => {
    return (
      post.node.fields.slug.indexOf("/blog/") != -1 &&
      post.node.fields.slug.length > 6
    )
  })

  return (
    <div className="body">
      <Layout link="blog">
        <SEO
          title="My Blog"
          description="Hi, I am Nwachukwu chibuike, an avid learner, programmer, web developer and enterpreneur. I love doing great work with my pc and always love to bring in organisation and uniqueness to every project."
          keywords="Nwachukwu chibuike, Chibuike Blog, Nwachukwu Blog, Web developer nigeria, VueJs, Lagos, FUTO"
        />
        <main id="about">
          <h1 className="lg-heading">
            {data.lhp}&nbsp;
            <span className="text-secondary" style={{ lineHeight: "1.6" }}>
              {data.lhs}
            </span>
          </h1>
          <h2 className="sm-heading">{data.intro}</h2>
          <div className="about-info">
            <div>{Image(props.data)}</div>
            <div className="bio">
              <h3 className="text-secondary"> {data.bio}</h3>
              <p>{data.bio_text}</p>
            </div>
            <Experience gridItems={edges} />
          </div>
        </main>
      </Layout>
      <Footer />
    </div>
  )
}

export default BlogPage
